body {
  margin: 0;
  font-family: gothic, -apple-system, BlinkMacSystemFont, 'gothic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: gothic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body::-webkit-scrollbar, body ::-webkit-scrollbar {
    background-color: #212529;
    width: 14px;
    outline: none;
}

body::-webkit-scrollbar-corner, body ::-webkit-scrollbar-corner {
    background-color: #212529;
}

body::-webkit-scrollbar-thumb, body ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(107, 107, 107);
    min-height: 24px;
    border: 3px solid rgb(43, 43, 43);
}

@font-face {
  font-family: 'gothic';
  src: url('./fonts/gothic.ttf');
}

@font-face {
  font-family: 'prometheus';
  src: url('./fonts/prometheus.ttf');
}

@font-face {
  font-family: 'Morganite-ExtraLight';
  src: url('./fonts/Morganite-ExtraLight.ttf');
}

@font-face {
  font-family: 'RomanD';
  src: url('./fonts/RomanD.ttf');
}
