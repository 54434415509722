.portfolio-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link-portfolio{
    outline: none;
    text-decoration: none;
}

.vl {
    border-left: 2px solid white;
    height: 50px;
    margin: 8px;
    margin-left: 20px;
}

.portfolio-grid {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 30%;
    -moz-column-width: 30%;
    column-width: 30%;
    padding: 0 16px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.portfolio-body {
    width: 100%;
    /* height: 100%; */
    background: #212529;
}


.portfolio-title {
    margin: 0;
    display: block;
    text-align: center;
    padding: 12px;
    color: white;
    font-weight: 400 !important;
}

.img-portfolio{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px !important;
    margin-bottom: 16px;
    /* page-break-inside: avoid; */
    overflow: hidden;
    object-fit: cover;

}

.portfolio-img {
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px !important;
    margin-bottom: 16px;
    page-break-inside: avoid;
}

.portfolio-logo {
    margin: 0;
}

@media only screen and (min-width: 780px) and (max-width: 1200px) {
    .portfolio-grid {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 45%;
        -moz-column-width: 45%;
        column-width: 45%;
        padding: 0 16px;
    }
}

@media only screen and (max-width: 780px) {
    .portfolio-grid {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 90%;
        -moz-column-width: 90%;
        column-width: 90%;
        padding: 0 16px;
    }

    .portfolio-img {
        background-size: cover;
        background-repeat: no-repeat;
        height: 360px !important;
        margin-bottom: 16px;
        page-break-inside: avoid;
    }

    .img-portfolio{
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 360px !important;
        margin-bottom: 16px;
        object-fit: cover;
        overflow: hidden;
    }

    .portfolio-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vl {
        height: 1px;
        width: 260px;
        border-top: 1px solid white;
        margin: auto;
    }
}

@media only screen and (max-width: 500px) {
    .portfolio-img {
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px !important;
        margin-bottom: 16px;
        page-break-inside: avoid;
    }

    .img-portfolio{
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px !important;
        margin-bottom: 16px;
        object-fit: cover;
        overflow: hidden;
    }
}