.carousel-responsivness {
    padding-top: 70px;
    z-index: 1;
}

.slogan {
    z-index: 3;
    position: absolute;
    height: calc(100vh);
    padding-top: 70px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: calc(100vh - 70px);
}

.slogan-text {
    color: #fff;
    font-size: 60pt;
    font-weight: bold;
    text-shadow: 2px 2px 4px #000000;
}

.car-wrapper {
    position: relative;
}

.carousel-image-height {
    height: calc(100vh - 70px);
    object-fit: cover;
    width: 100%;
    justify-content: center;
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
    .slogan-text {
        color: #fff;
        font-size: 40pt;
        font-weight: bold;
        text-shadow: 2px 2px 4px #000000;
    }
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
    .carousel-image-height {
        height: calc(70vh - 70px);
        object-fit: cover;
        width: 100%;
        justify-content: center;
    }

    .slogan {
        z-index: 3;
        position: absolute;
        height: calc(70vh - 70px);
        padding-top: 70px;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        line-height: calc(70vh - 140px);
    }
    
    .slogan-text {
        color: #fff;
        font-size: 24pt;
        font-weight: bold;
        text-shadow: 2px 2px 4px #000000;
    }
}


@media only screen and (min-width: 480px) and (max-width: 640px) {
    .carousel-responsivness {
        padding-top: 50px;
    }

    .d-block.w-100 {
        height: 400px;
        object-fit: cover;
    }

    .carousel-caption {
        padding: 0px;
        font-size: 14px;
    }

    h3 {
        font-size: 16pt;
    }

    .slogan {
        z-index: 3;
        position: absolute;
        height: calc(70vh - 50px);
        padding-top: 50px;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        line-height: calc(70vh - 100px);
    }
    
    .slogan-text {
        color: #fff;
        font-size: 16pt;
        font-weight: bold;
        text-shadow: 2px 2px 4px #000000;
    }
}

@media only screen and (max-width: 480px) {
    .carousel-responsivness {
        padding-top: 50px;
        z-index: 1;
    }

    .d-block.w-100 {
        height: 300px;
        object-fit: cover;
    }

    .carousel-caption {
        padding: 0px;
        font-size: 11px;
    }

    h3 {
        font-size: 14pt;
    }

    .slogan {
        z-index: 3;
        position: absolute;
        height: calc(70vh - 50px);
        padding-top: 50px;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        line-height: calc(35vh );
    }
    
    .slogan-text {
        color: #fff;
        font-size: 14pt;
        font-weight: bold;
        text-shadow: 2px 2px 4px #000000;
    }
}