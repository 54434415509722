.popup-backdrop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}

.success-message{
    color: #000;
}

.popup-wrapper {
    position: fixed;
    width: 400px;
    height: 580px;
    background: white;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 20px;
}

.alert-wrapper {
    position: fixed;
    width: 400px;
    height: 160px;
    background: white;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 20px;
}

.popup-email {
    border-radius: 15px;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid grey;
    margin-bottom: 10px;

}

.popup-textarea {
    padding: 8px;
    width: 100%;
    border-radius: 15px;
    border-width: 1px;
    border-color: grey;
    resize: none;

}

.popup-textarea:focus {
    border: 1px solid #313335;
    outline: none !important;
}

.popup-email:focus {
    border: 1px solid #313335;
    outline: none !important;
}

.popup-submit-button {
    color: white;
    font-weight: 500;
    width: 160px;
    background: #313131;
    padding: 0px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s;
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popup-submit-button:hover {
    background: #313335;
    color: white;
    box-shadow: 0px 5px 10px rgb(15, 15, 15);
    transform: translateY(-2px);
}

.popup-close-button {
    color: black;
    font-weight: 500;
    width: 80px;
    background: white;
    padding: 0px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s;
    outline: none;
    margin-top: 20px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.buttons-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.tos-alert{
    color: red;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 40px;
}

@media only screen and (max-width: 500px){
    .popup-wrapper {
        position: fixed;
        width: 90%;
        height: 540px;
        background: white;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        padding: 20px;
    }
}