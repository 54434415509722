.animation-container {
  display: inline-flex;
  position: relative;
}

.bg-light {
  background: #181A1B !important;
}

.nav-boot-buttons {
  text-transform: uppercase;
  font-family: gothic;
  inherits: unset;
  color: #c4c4c4 !important;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.nav-boot-buttons:hover {
  color: white !important;
}

.nav-boot-buttons::after {
  width: 0%;
  position: absolute;
  background-color: white;
  height: 3px;
  content: "";
  left: 0;
  bottom: 0;
  transition: 0.3s;
}

.nav-boot-buttons:hover::after {
  width: 100%;
}

.active.nav-boot-buttons::after {
  width: 100%;
}

.active.nav-boot-buttons {
  color: white !important;
}

.website-logo {
  width: 70px;
  height: 70px;

}

.banner-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.title-container {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-self: center;
  /* vertical-align: middle; */
  justify-content: center;
  line-height: 1;
}

.title {
  display: flex;
  flex: 0 0 auto;
  font-family: RomanD;
  color: white !important;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  font-size: 20pt;
  line-height: 1 !important;
  margin-block-end: 0em;
  margin-block-start: 0em;
  margin-left: 10px;

}

.subtitle {
  display: flex;
  flex: 0 0 auto;
  font-family: RomanD;
  color: white !important;
  text-transform: uppercase;
  text-align: end;
  font-size: 9pt;
  padding: 0px;
  margin: 0px;
  margin-right: 2px !important;
  justify-content: flex-end;
}

@media only screen and (max-width: 480px) {
  .website-logo {
    width: 50px;
    height: 50px;
  }

  .title {
    font-size: 16pt;
  }

  .subtitle {
    font-size: 6pt;
  }

  .animation-container {
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }
}