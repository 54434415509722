.footer {
    background: #181A1B;
    min-height: 400px;

}

.footer-wrapper {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-wrapper h5 {
    color: white;
}

.social-media-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
}

.social-media-item {
    margin-right: 20px;
}

.social-media-anchors {
    text-decoration: none;
    color: white;
}

.footer-left {
    margin-top: 40px;
}

.footer-left a {
    text-decoration: none;
    color: white;
    margin-bottom: 20px;
}

.footer-left p {
    margin-left: 10px;
}

.mailto {
    display: flex;
}

.footer-right {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.footer-right input,
textarea {
    display: flex;
    width: 400px;
    margin-bottom: 10px;
}

.footer-right label {
    color: white;
}

.footer-right button {
    width: 400px;
}

.footer-tba-input {
    color: white;
    background-color: #313335;
    border-width: 0px;
    outline: none;
    border-radius: 15px;
    padding-left: 15px;
    flex: auto;
    overflow: hidden;
}

.footer-select {
    color: white;
    background-color: #313335;
    border-width: 0px;
    outline: none;
    border-radius: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 30px;
    display: block;
    width: 100%
}

@media only screen and (max-width: 800px) {
    .footer {
        background: #181A1B;
        height: auto;
        width: 100%;
    }

    .footer-wrapper {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .footer-right {
        margin-bottom: 20px;
    }

    .footer-right input,
    textarea {
        width: 100%;
    }

    .footer-right button {
        width: 100%;
    }
}