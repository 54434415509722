.mission-back {
    min-height: calc(100vh - 70px);
    background-color: #212529;
    background-image: url('../../images/lines.png');
    background-size: cover;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mission-header{
    font-size: 21pt;
}


.mission-content {
    width: 100%;
    background-color: #212529EE;
    padding: 100px;

}

.mission-desc {
    color: white;
    text-align: center;
    background-color: #21252900;
}


.button-spacer{
    /* background-color: #212529; */
    padding: 20px 80px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.neumobutton {
    /* margin-top: 10px; */
    padding: 0.7em 2em;
    background: #212529;
    border: none;
    border-radius: 0rem;
    font-weight: 600;
    font-size: 11pt;
    color: #fff;
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(37, 41, 47, 0.7),
        -6px -6px 10px rgba(29, 32, 37, 0.5),
        2px 4px 8px rgba(0, 0, 0, .075),
        2px 4px 8px rgba(0, 0, 0, .15);
}

.neumobutton:hover {
    color: white;
    box-shadow: -2px -2px 6px rgba(37, 41, 47, .6),
        -2px -2px 4px rgba(37, 41, 47, .4),
        2px 2px 2px rgba(37, 41, 47, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
}

.neumobutton.active {
    box-shadow: inset -8px -8px 6px rgba(37, 41, 47, .7),
        inset -6px -6px 2px rgba(37, 41, 47, .5),
        inset 8px 8px 4px rgba(37, 41, 47, .075),
        inset 6px 8px 2px rgba(0, 0, 0, .15);
}
.neumobutton:active {
    box-shadow: inset -8px -8px 6px rgba(37, 41, 47, .7),
        inset -6px -6px 2px rgba(37, 41, 47, .5),
        inset 8px 8px 4px rgba(37, 41, 47, .075),
        inset 6px 8px 2px rgba(0, 0, 0, .15);
}

@media only screen and (min-width: 740px) and (max-width: 780px) {
    .mission-desc {
        color: white;
        text-align: center;
        font-size: 14pt;
    }
}

@media only screen and (min-width: 500px) and (max-width: 740px) {
    .mission-desc {
        color: white;
        text-align: center;
        font-size: 12pt;
    }

    .mission-header{
        font-size: 14pt;
    }

    .neumobutton{
        font-size: 10pt;
    }

    .mission-content {
        width: 100%;
        padding: 20px;
    }

    .mission-back {
        padding: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .mission-desc {
        color: white;
        text-align: center;
        font-size: 10pt;
    }

    .mission-header{
        font-size: 14pt;
    }

    .neumobutton{
        font-size: 9pt;
    }

    .mission-content {
        width: 100%;
        padding: 20px;
    }

    .mission-back {
        padding: 0px;
    }
}