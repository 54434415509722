
.packages-row{
    display: flex;
    flex-direction: row;
    background-color: #212529;
    color: #fff;
    flex-wrap: wrap;
    position: relative;
    height: fit-content;
    justify-content: space-around;
}

.continous-background{
    background: #181A1B;
    /* height: 220px; */
    text-align: center;
    color: white;
    padding-top: 20px;
    padding-right: 80px;
    padding-left: 80px;
    font-family: gothic;
}

.continous-background-extension{
    height: 125px;
    background: #181A1B;
}

h1{
    margin-bottom: 0px;
    font-family: RomanD;
    font-weight: bold;
}

hr.solid {
    border-top: 3px solid rgb(255, 255, 255);
  }

.packageOptions-content-parent{
    transition: height ease 0.7s;
    overflow: hidden;
    background-color: #212529;
}