
.cardstyle-container{
    display: block;
    width: 100%;
    padding-top: 40px;
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
    font-family: gothic;
}

.cardstyle-skewbox-left{
    height: 400px;
    width: 100%;
    background-color: #181A1B;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardstyle-skewbox-right{
    height: 400px;
    width: 100%;
    background-color: #181A1B;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardstyle-content{
    width: calc(40% - 50px);
    height: 100%;
    color: #fff;
    background-color: #181A1B;
    padding: 20px 20px;
    /* line-height: 1.5em; */
    font-size: 18px;
    /* text-align: justify; */
}

.cardstyle-imagebox{
    width: calc(80% + 50px);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-attachment: fixed; */
    /* border-left: 100px solid white;
    border-bottom: 400px solid transparent; */
}

.cardstyle-imagebox.small-angle{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 5% 100%);
}

.cardstyle-imagebox.big-angle{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%);
}

.cardstyle-imagebox-left{
    width: calc(80% + 50px);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-attachment: scroll; */
    /* border-left: 100px solid white;
    border-bottom: 400px solid transparent; */
}

.cardstyle-imagebox-left.small-angle{
    clip-path: polygon(0 0, 100% 0%, 95% 100%, 0 100%);
}

.cardstyle-imagebox-left.medium-angle{
    clip-path: polygon(0 0, 100% 0%, 87% 100%, 0 100%);
}

.cardstyle-imagebox-left.big-angle{
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0 100%);
}

.cardstyle-h1{
   text-transform: uppercase; 
}

.services-p{
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (max-width: 1200px){
    .cardstyle-content{
        width: calc(40% - 50px);
        height: 100%;
        background-color: #181A1B;
        padding: 20px 20px;
        /* line-height: 1.5em; */
        font-size: 16px;
        /* text-align: justify; */
    }
}

@media only screen and (max-width: 930px){
    .cardstyle-content{
        width: calc(40% - 50px);
        height: 100%;
        background-color: #181A1B;
        padding: 20px 20px;
        /* line-height: 1.5em; */
        font-size: 14px;
        /* text-align: justify; */
    }
}

@media only screen and (min-width: 680px) and (max-width: 790px){
    .cardstyle-content{
        width: calc(40% - 50px);
        height: 100%;
        background-color: #181A1B;
        padding: 20px 20px;
        /* line-height: 1.5em; */
        font-size: 13px;
        /* text-align: justify; */
    }
}

@media only screen and (max-width: 680px){
    .mobile-drop-shadow{
        filter: drop-shadow( 0 20px 20px rgba(0, 0, 0, 0.5));
    }

    .cardstyle-h1{
        font-size: 16pt; 
     }

    .cardstyle-container{
        display: block;
        width: 100%;
        padding-top: 40px;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .cardstyle-skewbox-left{
        width: 100%;
        display: flex;
        height: fit-content;
        flex-direction: column;
        background-color: #181A1B;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
        /* clip-path: polygon(0 0, 100% 0%, 90% 100%, 0 100%); */
    }

    .cardstyle-skewbox-right{
        width: 100%;
        display: flex;
        height: fit-content;
        flex-direction: column-reverse;
        background-color: #181A1B;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
        /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%); */
    }

    .cardstyle-content{
        background-color: #181A1B;
        color: #fff;
        width: 100%;
        height: fit-content;
        padding: 10px 20px;
        font-size: 11px;
        text-align: start !important;
    }

    .cardstyle-imagebox{
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
         
    }
    .cardstyle-imagebox-left{
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .cardstyle-imagebox-left.small-angle{
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%) !important;
    }
    
    .cardstyle-imagebox-left.medium-angle{
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 87%) !important;
    }
    
    .cardstyle-imagebox-left.big-angle{
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%) !important;
    }

    .cardstyle-imagebox.small-angle{
        clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%) !important;
    }
    
    .cardstyle-imagebox.big-angle{
        clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%) !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 640px){
    .cardstyle-imagebox{
        height: 300px;
    }
    .cardstyle-imagebox-left{
        height: 300px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 640px){
    .cardstyle-imagebox{
        height: 300px;
    }
    .cardstyle-imagebox-left{
        height: 300px;
    }

    
}