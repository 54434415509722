.package-container {
    /* width: 300px; */
    text-align: center;
    flex: 1 0 20%;
    /* offset-position: 0% 50%; */
    top: -125px;
    position: relative;
    padding-top: 20px;
}

.package-text {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-family: gothic;
    /* text-align: justify; */
}

.package-text.buttonActive {
    border-left-style: solid;
    border-left-color: white;
    border-right-color: white;
    border-right-style: solid;
    border-width: 3px;
    color: white !important;
}

h5, h3 {
    margin-top: 8px;
    color: #fff;
    text-transform: uppercase;
    font-family: RomanD;
    font-weight: bold;
    /* font-weight: 700; */
}

h5.buttonActive {
    color: white !important;
    text-shadow: 0px 5px 5px #7c7c7c;
    transform: translateY(-2px);
}

h5.unselected {
    color: #fff;
}

.package-text.unselected {
    color: #fff;
}

.description-container {
    width: 300px;
    display: block;
    margin: 0;
    justify-content: start;
}

.description-outer-container {
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.package-image {
    width: 300px;
    height: 300px;
    margin: auto;
    display: block;
    object-fit: cover;
    box-shadow: -6px -6px 14px rgba(37, 41, 47, 0.7),
        -6px -6px 10px rgba(0, 0, 0, .5),
        6px 6px 8px rgba(0, 0, 0, .075),
        6px 6px 10px rgba(0, 0, 0, .15);
}

.package-button {
    color: white;
    width: 160px;
    background: #313335;
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s;
    outline: none;
    /* clip-path: polygon(20% 0%, 100% 0, 80% 100%, 0% 100%); */
}

.button-unselected {
    background: #696969;
}

.package-button:hover {
    background: black;
    box-shadow: 0px 5px 10px #212529;
    transform: translateY(-1px);
}

.package-button.buttonActive {
    background: black !important;
}

@media only screen and (max-width: 1300px) {
    .package-container {
        flex: 1 0 45%;
    }
}

@media only screen and (max-width: 640px) {
    .package-container {
        flex: 1 0 55%;
    }
}