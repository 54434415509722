:root {
    --rmdp-primary-teal: #696969;
    --rmdp-secondary-teal: #696969;
    --rmdp-shadow-teal: #696969;
    --rmdp-today-teal: #696969;
    --rmdp-hover-teal: #696969;
    --rmdp-deselect-teal: #696969;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.packageOptions-container{
    background-color: #212529;
    display: block;
    color: white;
    line-height: 40px;
    
}

.design-style-textarea{
    margin-left: 10px;
    margin-bottom: 0px;
    padding: 8px;
    width: 100%;
    border-radius: 15px;
    border-width: 0px;
    outline: none;
    color: #ffffff;
    background-color: #313335;
    border-color: #212529;
    resize: none;
}

/* .hidden{
    display: none;
} */

.packageOptions-header{
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 20px;
}

.packageOptions-divider{
    background-color: white;
    height: 3px;
    width: 280px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.p{
    margin: 0px;
    
}

.packageOptions-info-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.packageOptions-info-innerrow{
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
}

.packageOptions-tba{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
}

.packageOptions-styles{
    display: flex;
    flex-direction: row;
    /* margin-bottom: 20px; */
}

.design-styles{
    display: flex;
    flex-direction: row;
    flex: 1 0 45%;
    flex-wrap: wrap;
}

.packageOptions-calendar{
    z-index: 0 !important;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    margin-right: 40px;
}

.calender-header-text{
    font-size: 12pt;
}

.designStyle-button{
    color: white;
    background: #313335;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s; 
    margin-left: 10px;
    margin-bottom: 10px; 
    outline: none;
    flex: 1 1 45%;
    flex-wrap: wrap;
}

.submit-button{
    color: black;
    font-weight: 500;
    width: 160px;
    background: white;
    padding: 0px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s;  
    outline: none;
    margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.submit-button:hover{
    background: #313131;
    color: white;
    box-shadow: 0px 5px 10px rgb(15, 15, 15);
    transform: translateY(-2px);
}

.designStyle-button.buttonActive{
    background: black !important;
}


.tba-input{
    color: white;
    background-color: #313335;
    border-width: 0px;
    outline: none;
    border-radius: 15px;
    margin-left: 20px;
    padding-left: 15px;
    flex: auto;
    overflow: hidden;
}

.packageOptions-picker{
    display: flex;
    justify-content: space-between;
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 20px;
}

.packageOptions-mobile{
    padding-bottom: 40px;
}

.bg-dark{
    background-color: #313335 !important;
    box-shadow: none !important;
}

.rmdp-week-day{
    color: #c4c7ca !important;
}

.rmdp-arrow {
    border: solid #fff !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block !important;
    height: 3px !important;
    margin-top: 5px !important;
    padding: 2px !important;
    width: 3px !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: white;
    box-shadow: 0 0 3px #696969;
    color: white;
}

.rmdp-day.rmdp-today span {
    background-color: white;
    color: black;
}

.checkbox-label{
    padding-left: 10px;
}

.checkbox-container {
    display: block;
    position: relative;
    width: fit-content;
    padding-left: 20px;
    margin-bottom: 15px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14pt;
}
  
input[type=checkbox] {
    visibility: hidden;
}
  
.checkbox-span {
    position: absolute;
    top: 8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #313335;
    border-radius: 5px;
    transition: ease 0.3s;
}
  
.checkbox-container:hover input ~ .checkbox-span {
    background-color: #b0b0b0;
}
  
.checkbox-container input:active ~ .checkbox-span {
    background-color: white;
}
  
.checkbox-container input:checked ~ .checkbox-span {
    background-color: #313131;
}
  
.checkbox-span:after {
    content: "";
    position: absolute;
    display: none;
    transition: ease 0.3s;
}
  
.checkbox-container input:checked ~ .checkbox-span:after {
    display: block;
}
  
.checkbox-container .checkbox-span:after {
    left: 9px;
    bottom: 5px;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 4px 4px 0;
    /* border-radius: 2px; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (min-width: 780px) and (max-width: 1060px) {
    .packageOptions-info-row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .design-style-textarea{
        margin-left: 0px;
        margin-bottom: 0px;
        padding: 8px;
        width: 100%;
        border-radius: 15px;
        border-width: 0px;
        outline: none;
        color: #ffffff;
        background-color: #313335;
        border-color: #212529;
        resize: none;
    }

    .packageOptions-calendar{
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        margin-right: 0px;
    }

    .calender-header-text{
        font-size: 11pt;
    }

    .design-styles{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .designStyle-button{
        flex: 0 0 22%;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11pt;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px; 
    }

    .packageOptions-styles{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .packageOptions-tba{
        display: flex;
        flex-direction: column;
    }

    .tba-input{
        margin-left: 0px;
    }

    .checkbox-container {
        font-size: 11pt;
    }

    h3{
        font-size: 13pt;
    }
}

@media only screen and (max-width: 780px){
    .packageOptions-info-row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .design-style-textarea{
        margin-left: 0px;
        margin-bottom: 0px;
        padding: 8px;
        width: 100%;
        border-radius: 15px;
        border-width: 0px;
        outline: none;
        color: #ffffff;
        background-color: #313335;
        border-color: #212529;
        resize: none;
    }

    .packageOptions-picker{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 20px;
    }

    .packageOptions-mobile{
        padding-bottom: 40px;
    }

    .packageOptions-calendar{
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        margin-right: 0px;
    }

    .calender-header-text{
        font-size: 11pt;
    }

    .design-styles{
        display: flex;
        flex-direction: column;
    }

    .designStyle-button{
        flex: 1 1 auto;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11pt;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px; 
    }

    .packageOptions-styles{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .p{
        font-size: 12pt;
    }

    .packageOptions-tba{
        display: flex;
        flex-direction: column;
    }

    .tba-input{
        margin-left: 0px;
    }

    .checkbox-container {
        font-size: 11pt;
    }

    h3{
        font-size: 13pt;
    }
}