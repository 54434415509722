.loading-state-bg {
    background-image: url('../../images/lines.png');
    background-size: cover;
    object-fit: cover;
    background-color: #212529;
    height: 100vh;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rotate {
    animation: rotation 3s infinite linear;
}

@keyframes rotation {
    from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
  /* 100% {
    transform: rotateY(0deg);
  } */
}

.loading-center {
    justify-content: center;
}

.img-loading{
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
}

.title-loading {
    display: flex;
    flex: 0 0 auto;
    font-family: RomanD;
    color: white !important;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    font-size: 28pt;
    line-height: 1 !important;
    margin-block-end: 0em;
    margin-block-start: 0em;
    margin-left: 10px;

}

.subtitle-loading {
    display: flex;
    flex: 0 0 auto;
    font-family: RomanD;
    color: white !important;
    text-transform: uppercase;
    text-align: end;
    font-size: 14pt;
    padding: 0px;
    margin: 0px;
    margin-right: 2px !important;
    justify-content: flex-end;
}

@media only screen and (max-width: 480px) {
    .subtitle-loading{
        display: none;
        visibility: hidden;
    }
    .title-loading{
        visibility: hidden;
    }
    .title-container{
        visibility: hidden;
        display: none;
    }
}