.wrapper {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #212529;
    color: white;
}

.portfolio-button {
    color: white;
    background: #313335;
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    border-width: 0px;
    text-align: center;
    transition: ease-in-out 0.3s;
    outline: none;
    text-decoration: none;
}

.portfolio-button:hover {
    background: black;
    box-shadow: 0px 5px 10px #212529;
    transform: translateY(-1px);
    color: white;
}

.portfolio-button-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.portfolio-preview {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-auto-columns: minmax(1fr, auto); */
    /* grid-template-rows: repeat(2, 1fr); */
    gap: 1rem;
    height: 500px;
    grid-auto-rows: auto;
}

.portfolio-pics {
    /* background-attachment: fixed; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* border: 1px solid #939393; */
    box-shadow: -6px -6px 14px rgba(37, 41, 47, 0.7),
        -6px -6px 10px rgba(0, 0, 0, .5),
        6px 6px 8px rgba(0, 0, 0, .075),
        6px 6px 10px rgba(0, 0, 0, .15);
    /* width: 100%; */
    /* height: 100%; */
}

.one {
    grid-column: 1/3;
}

.two {
    height: 100%;
    grid-column: 3/4;
}

.three {
    height: 100%;
    grid-column: 4/6;
    grid-row: 1/3;
}

.four {
    grid-column: 1/2;
    height: 100%;
}

.five {
    grid-column: 2/4;
}

.description {
    margin: 40px;
    text-align: center;
}

#aboutus {
    font-size: 16pt;
}

.description hr {
    border-top: 3px solid white;
    color: white !important;
}

@media only screen and (max-width: 600px) {
    .portfolio-preview {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
        gap: 0.5rem;
        height: 1000px;
        grid-auto-rows: auto;
    }

    .one {
        grid-column: 1/3;
    }

    .two {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .three {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .four {
        width: 100%;
        grid-column: 1/3;
        grid-row: 3/5;
    }

    .five {
        grid-column: 1/3;
        grid-row: 5/6;
    }

    #aboutus {
        font-size: 14pt;
    }
}