.mission-body{
    min-height: 100vh;
    background-color: #212529;
    background-image: url('../../../images/lines.png');
    background-size: cover;
    object-fit: cover;
    color: #fff;
    text-align: center;
}

.mission-blur{
    background-color: #212529CC;
}

.mission-section{
    /* display: flex; */
    width: 90%;
    /* justify-content: space-between; */
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
}

.mission-title{
    margin-bottom: 40px;
}


